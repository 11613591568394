var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.keyForRerendering != 0
    ? _c("div", { key: _vm.keyForRerendering, attrs: { id: "rootVM" } }, [
        _vm._m(0),
        _vm.loginUserInfo.userRoleCd == "00"
          ? _c("div", { attrs: { id: "adminView" } }, [
              _vm._m(1),
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  [
                    _vm.curCompanyNm
                      ? _c("h2", [
                          _vm._v(_vm._s(_vm.curCompanyNm) + " 안전관리자")
                        ])
                      : _vm._e(),
                    _vm.curCompanyNm == null
                      ? _c("h2", [
                          _vm._v(_vm._s(_vm.curSiteNm) + " 안전관리자")
                        ])
                      : _vm._e(),
                    _c("KendoGrid", {
                      ref: "supervisorGrid",
                      attrs: {
                        "auto-bind": true,
                        "api-url":
                          _vm.settingPushAlarmUrl.inqSafetySupervisorInfo,
                        columns: _vm.siteSafetySupervisorGridColumns,
                        "apply-search-condition":
                          _vm.applySearchStateOnGridLoadForGettingSupervisorInfo,
                        height: 300
                      },
                      on: {
                        "selected-row-item-changed": _vm.selectedRowItemChanged
                      }
                    })
                  ],
                  1
                ),
                _vm.isEditMode
                  ? _c(
                      "div",
                      {
                        staticClass: "panel panel-flat",
                        attrs: { id: "details" }
                      },
                      [
                        _c("div", { staticClass: "panel-heading" }, [
                          _vm._m(2),
                          _c("div", { staticClass: "heading-elements" }, [
                            _c("ul", { staticClass: "icons-list" }, [
                              _c("li", [
                                _c("a", {
                                  attrs: { "data-action": "collapse" },
                                  on: { click: _vm.pannelHidden }
                                })
                              ])
                            ])
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "panel-body panel-body-center" },
                          [
                            _c("div", { staticClass: "row panel-detail" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    padding: "10px",
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _vm._m(3),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "align-items": "center"
                                      }
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.detailsItem.userInfo
                                                .aiPushRecvYn,
                                            expression:
                                              "detailsItem.userInfo.aiPushRecvYn"
                                          }
                                        ],
                                        staticClass: "switch",
                                        attrs: {
                                          type: "checkbox",
                                          id: "total_push_receive_switch",
                                          "true-value": "1",
                                          "false-value": "0"
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.detailsItem.userInfo
                                              .aiPushRecvYn
                                          )
                                            ? _vm._i(
                                                _vm.detailsItem.userInfo
                                                  .aiPushRecvYn,
                                                null
                                              ) > -1
                                            : _vm._q(
                                                _vm.detailsItem.userInfo
                                                  .aiPushRecvYn,
                                                "1"
                                              )
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a =
                                                _vm.detailsItem.userInfo
                                                  .aiPushRecvYn,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? "1" : "0"
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.detailsItem.userInfo,
                                                    "aiPushRecvYn",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.detailsItem.userInfo,
                                                    "aiPushRecvYn",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.detailsItem.userInfo,
                                                "aiPushRecvYn",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "switch_label",
                                          attrs: {
                                            for: "total_push_receive_switch"
                                          }
                                        },
                                        [
                                          _vm.detailsItem.userInfo
                                            .aiPushRecvYn == 1
                                            ? _c("div", { staticClass: "on" }, [
                                                _vm._v("on")
                                              ])
                                            : _c(
                                                "div",
                                                { staticClass: "off" },
                                                [_vm._v("off")]
                                              ),
                                          _c("span", { staticClass: "onf_btn" })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "h4",
                                { staticStyle: { "text-align": "left" } },
                                [_vm._v("주의 알림 설정")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12",
                                  staticStyle: {
                                    height: "35vh",
                                    "padding-right": "0px",
                                    overflow: "auto",
                                    "z-index": "0"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "table-wrapper",
                                      attrs: { id: "danger-table", danger: "" }
                                    },
                                    [
                                      _c(
                                        "table",
                                        { staticClass: "table table-hover" },
                                        [
                                          _vm._m(4),
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(
                                                _vm.detailsItem
                                                  .warnWorkPushSetting,
                                                function(row, index) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        id: "warnitem" + index
                                                      }
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(row.objectNm)
                                                        )
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "center",
                                                                "align-items":
                                                                  "center"
                                                              }
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      row.recvYn,
                                                                    expression:
                                                                      "row.recvYn"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "switch setting_switch",
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  id:
                                                                    "warn_push_receive_switch" +
                                                                    index,
                                                                  "true-value":
                                                                    "1",
                                                                  "false-value":
                                                                    "0"
                                                                },
                                                                domProps: {
                                                                  checked: Array.isArray(
                                                                    row.recvYn
                                                                  )
                                                                    ? _vm._i(
                                                                        row.recvYn,
                                                                        null
                                                                      ) > -1
                                                                    : _vm._q(
                                                                        row.recvYn,
                                                                        "1"
                                                                      )
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          row.recvYn,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c = $$el.checked
                                                                          ? "1"
                                                                          : "0"
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v = null,
                                                                          $$i = _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              row,
                                                                              "recvYn",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              row,
                                                                              "recvYn",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          row,
                                                                          "recvYn",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.setClockStatus(
                                                                        row.recvYn,
                                                                        "warn_time",
                                                                        index
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "switch_label",
                                                                  attrs: {
                                                                    for:
                                                                      "warn_push_receive_switch" +
                                                                      index
                                                                  }
                                                                },
                                                                [
                                                                  row.recvYn ==
                                                                  1
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "on"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "on"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "off"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "off"
                                                                          )
                                                                        ]
                                                                      ),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "onf_btn"
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                              padding: "5px",
                                                              display: "flex",
                                                              "justify-content":
                                                                "center",
                                                              "align-items":
                                                                "center"
                                                            }
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    row.recvStrtTime,
                                                                  expression:
                                                                    "row.recvStrtTime"
                                                                }
                                                              ],
                                                              class:
                                                                "input_time warn_time" +
                                                                index,
                                                              attrs: {
                                                                type: "time",
                                                                name:
                                                                  "start_time"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  row.recvStrtTime
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    row,
                                                                    "recvStrtTime",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            }),
                                                            _vm._v("   ~   "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    row.recvEndTime,
                                                                  expression:
                                                                    "row.recvEndTime"
                                                                }
                                                              ],
                                                              class:
                                                                "input_time warn_time" +
                                                                index,
                                                              attrs: {
                                                                type: "time",
                                                                name: "end_time"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  row.recvEndTime
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    row,
                                                                    "recvEndTime",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._m(5),
                                              _vm._m(6)
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "h4",
                                { staticStyle: { "text-align": "left" } },
                                [_vm._v("위험 알림 설정")]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "col-md-12",
                                  staticStyle: {
                                    height: "35vh",
                                    "padding-right": "0px",
                                    overflow: "auto"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "table-wrapper",
                                      attrs: { id: "danger-table", danger: "" }
                                    },
                                    [
                                      _c(
                                        "table",
                                        { staticClass: "table table-hover" },
                                        [
                                          _vm._m(7),
                                          _c(
                                            "tbody",
                                            [
                                              _vm._l(
                                                _vm.detailsItem
                                                  .dangerPushSetting,
                                                function(row, index) {
                                                  return _c(
                                                    "tr",
                                                    {
                                                      key: index,
                                                      attrs: {
                                                        id: "dangeritem" + index
                                                      }
                                                    },
                                                    [
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(row.objectNm)
                                                        )
                                                      ]),
                                                      _c("td", [
                                                        _vm._v(
                                                          _vm._s(row.targetNm)
                                                        )
                                                      ]),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticStyle: {
                                                            "text-align":
                                                              "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display: "flex",
                                                                "justify-content":
                                                                  "center",
                                                                "align-items":
                                                                  "center"
                                                              }
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      row.recvYn,
                                                                    expression:
                                                                      "row.recvYn"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "switch setting_switch",
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  id:
                                                                    "danger_push_receive_switch" +
                                                                    index,
                                                                  "true-value":
                                                                    "1",
                                                                  "false-value":
                                                                    "0"
                                                                },
                                                                domProps: {
                                                                  checked: Array.isArray(
                                                                    row.recvYn
                                                                  )
                                                                    ? _vm._i(
                                                                        row.recvYn,
                                                                        null
                                                                      ) > -1
                                                                    : _vm._q(
                                                                        row.recvYn,
                                                                        "1"
                                                                      )
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          row.recvYn,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c = $$el.checked
                                                                          ? "1"
                                                                          : "0"
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v = null,
                                                                          $$i = _vm._i(
                                                                            $$a,
                                                                            $$v
                                                                          )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              row,
                                                                              "recvYn",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              row,
                                                                              "recvYn",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          row,
                                                                          "recvYn",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.setClockStatus(
                                                                        row.recvYn,
                                                                        "danger_time",
                                                                        index
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              }),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "switch_label",
                                                                  attrs: {
                                                                    for:
                                                                      "danger_push_receive_switch" +
                                                                      index
                                                                  }
                                                                },
                                                                [
                                                                  row.recvYn ==
                                                                  1
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "on"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "on"
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "off"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "off"
                                                                          )
                                                                        ]
                                                                      ),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "onf_btn"
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c("td", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              width: "100%",
                                                              padding: "5px",
                                                              display: "flex",
                                                              "justify-content":
                                                                "center",
                                                              "align-items":
                                                                "center"
                                                            }
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    row.recvStrtTime,
                                                                  expression:
                                                                    "row.recvStrtTime"
                                                                }
                                                              ],
                                                              class:
                                                                "input_time danger_time" +
                                                                index,
                                                              attrs: {
                                                                type: "time",
                                                                name:
                                                                  "start_time"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  row.recvStrtTime
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    row,
                                                                    "recvStrtTime",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            }),
                                                            _vm._v("   ~   "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    row.recvEndTime,
                                                                  expression:
                                                                    "row.recvEndTime"
                                                                }
                                                              ],
                                                              class:
                                                                "input_time danger_time" +
                                                                index,
                                                              attrs: {
                                                                type: "time",
                                                                name: "end_time"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  row.recvEndTime
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    row,
                                                                    "recvEndTime",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              ),
                                              _vm._m(8),
                                              _vm._m(9)
                                            ],
                                            2
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "text-right",
                                  staticStyle: { "margin-bottom": "15px" }
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-labeled bg-primary",
                                      on: { click: _vm.updateData }
                                    },
                                    [_vm._m(10), _vm._v(" 적용 ")]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ])
          : _c("div", [
              _c("div", { staticClass: "content" }, [
                _c(
                  "div",
                  { staticClass: "panel panel-flat", attrs: { id: "details" } },
                  [
                    _c("div", { staticClass: "panel-body panel-body-center" }, [
                      _c("div", { staticClass: "row panel-detail" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              padding: "10px",
                              display: "flex",
                              "justify-content": "center",
                              "align-items": "center"
                            }
                          },
                          [
                            _vm._m(11),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center"
                                }
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.detailsItem.userInfo.aiPushRecvYn,
                                      expression:
                                        "detailsItem.userInfo.aiPushRecvYn"
                                    }
                                  ],
                                  staticClass: "switch",
                                  attrs: {
                                    type: "checkbox",
                                    id: "total_push_receive_switch",
                                    "true-value": "1",
                                    "false-value": "0"
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.detailsItem.userInfo.aiPushRecvYn
                                    )
                                      ? _vm._i(
                                          _vm.detailsItem.userInfo.aiPushRecvYn,
                                          null
                                        ) > -1
                                      : _vm._q(
                                          _vm.detailsItem.userInfo.aiPushRecvYn,
                                          "1"
                                        )
                                  },
                                  on: {
                                    change: function($event) {
                                      var $$a =
                                          _vm.detailsItem.userInfo.aiPushRecvYn,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? "1" : "0"
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              _vm.detailsItem.userInfo,
                                              "aiPushRecvYn",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              _vm.detailsItem.userInfo,
                                              "aiPushRecvYn",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          _vm.detailsItem.userInfo,
                                          "aiPushRecvYn",
                                          $$c
                                        )
                                      }
                                    }
                                  }
                                }),
                                _c(
                                  "label",
                                  {
                                    staticClass: "switch_label",
                                    attrs: { for: "total_push_receive_switch" }
                                  },
                                  [
                                    _vm.detailsItem.userInfo.aiPushRecvYn == 1
                                      ? _c("div", { staticClass: "on" }, [
                                          _vm._v("on")
                                        ])
                                      : _c("div", { staticClass: "off" }, [
                                          _vm._v("off")
                                        ]),
                                    _c("span", { staticClass: "onf_btn" })
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c("h4", { staticStyle: { "text-align": "left" } }, [
                          _vm._v("주의 알림 설정")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-md-12",
                            staticStyle: {
                              height: "35vh",
                              "padding-right": "0px",
                              overflow: "auto",
                              "z-index": "0"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "table-wrapper",
                                attrs: { id: "danger-table", danger: "" }
                              },
                              [
                                _c(
                                  "table",
                                  { staticClass: "table table-hover" },
                                  [
                                    _vm._m(12),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.detailsItem.warnWorkPushSetting,
                                          function(row, index) {
                                            return _c(
                                              "tr",
                                              {
                                                key: index,
                                                attrs: {
                                                  id: "warnitem" + index
                                                }
                                              },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(row.objectNm))
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "center",
                                                          "align-items":
                                                            "center"
                                                        }
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: row.recvYn,
                                                              expression:
                                                                "row.recvYn"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "switch setting_switch",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "warn_push_receive_switch" +
                                                              index,
                                                            "true-value": "1",
                                                            "false-value": "0"
                                                          },
                                                          domProps: {
                                                            checked: Array.isArray(
                                                              row.recvYn
                                                            )
                                                              ? _vm._i(
                                                                  row.recvYn,
                                                                  null
                                                                ) > -1
                                                              : _vm._q(
                                                                  row.recvYn,
                                                                  "1"
                                                                )
                                                          },
                                                          on: {
                                                            change: [
                                                              function($event) {
                                                                var $$a =
                                                                    row.recvYn,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c = $$el.checked
                                                                    ? "1"
                                                                    : "0"
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v = null,
                                                                    $$i = _vm._i(
                                                                      $$a,
                                                                      $$v
                                                                    )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        row,
                                                                        "recvYn",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        row,
                                                                        "recvYn",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    row,
                                                                    "recvYn",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                              function($event) {
                                                                return _vm.setClockStatus(
                                                                  row.recvYn,
                                                                  "warn_time",
                                                                  index
                                                                )
                                                              }
                                                            ]
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "switch_label",
                                                            attrs: {
                                                              for:
                                                                "warn_push_receive_switch" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            row.recvYn == 1
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "on"
                                                                  },
                                                                  [_vm._v("on")]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "off"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "off"
                                                                    )
                                                                  ]
                                                                ),
                                                            _c("span", {
                                                              staticClass:
                                                                "onf_btn"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        padding: "5px",
                                                        display: "flex",
                                                        "justify-content":
                                                          "center",
                                                        "align-items": "center"
                                                      }
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              row.recvStrtTime,
                                                            expression:
                                                              "row.recvStrtTime"
                                                          }
                                                        ],
                                                        class:
                                                          "input_time warn_time" +
                                                          index,
                                                        attrs: {
                                                          type: "time",
                                                          name: "start_time"
                                                        },
                                                        domProps: {
                                                          value:
                                                            row.recvStrtTime
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              row,
                                                              "recvStrtTime",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v("   ~   "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              row.recvEndTime,
                                                            expression:
                                                              "row.recvEndTime"
                                                          }
                                                        ],
                                                        class:
                                                          "input_time warn_time" +
                                                          index,
                                                        attrs: {
                                                          type: "time",
                                                          name: "end_time"
                                                        },
                                                        domProps: {
                                                          value: row.recvEndTime
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              row,
                                                              "recvEndTime",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._m(13),
                                        _vm._m(14)
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c("h4", { staticStyle: { "text-align": "left" } }, [
                          _vm._v("위험 알림 설정")
                        ]),
                        _c(
                          "div",
                          {
                            staticClass: "col-md-12",
                            staticStyle: {
                              height: "35vh",
                              "padding-right": "0px",
                              overflow: "auto"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "table-wrapper",
                                attrs: { id: "danger-table", danger: "" }
                              },
                              [
                                _c(
                                  "table",
                                  { staticClass: "table table-hover" },
                                  [
                                    _vm._m(15),
                                    _c(
                                      "tbody",
                                      [
                                        _vm._l(
                                          _vm.detailsItem.dangerPushSetting,
                                          function(row, index) {
                                            return _c(
                                              "tr",
                                              {
                                                key: index,
                                                attrs: {
                                                  id: "dangeritem" + index
                                                }
                                              },
                                              [
                                                _c("td", [
                                                  _vm._v(_vm._s(row.objectNm))
                                                ]),
                                                _c("td", [
                                                  _vm._v(_vm._s(row.targetNm))
                                                ]),
                                                _c(
                                                  "td",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "center"
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "justify-content":
                                                            "center",
                                                          "align-items":
                                                            "center"
                                                        }
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: row.recvYn,
                                                              expression:
                                                                "row.recvYn"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "switch setting_switch",
                                                          attrs: {
                                                            type: "checkbox",
                                                            id:
                                                              "danger_push_receive_switch" +
                                                              index,
                                                            "true-value": "1",
                                                            "false-value": "0"
                                                          },
                                                          domProps: {
                                                            checked: Array.isArray(
                                                              row.recvYn
                                                            )
                                                              ? _vm._i(
                                                                  row.recvYn,
                                                                  null
                                                                ) > -1
                                                              : _vm._q(
                                                                  row.recvYn,
                                                                  "1"
                                                                )
                                                          },
                                                          on: {
                                                            change: [
                                                              function($event) {
                                                                var $$a =
                                                                    row.recvYn,
                                                                  $$el =
                                                                    $event.target,
                                                                  $$c = $$el.checked
                                                                    ? "1"
                                                                    : "0"
                                                                if (
                                                                  Array.isArray(
                                                                    $$a
                                                                  )
                                                                ) {
                                                                  var $$v = null,
                                                                    $$i = _vm._i(
                                                                      $$a,
                                                                      $$v
                                                                    )
                                                                  if (
                                                                    $$el.checked
                                                                  ) {
                                                                    $$i < 0 &&
                                                                      _vm.$set(
                                                                        row,
                                                                        "recvYn",
                                                                        $$a.concat(
                                                                          [$$v]
                                                                        )
                                                                      )
                                                                  } else {
                                                                    $$i > -1 &&
                                                                      _vm.$set(
                                                                        row,
                                                                        "recvYn",
                                                                        $$a
                                                                          .slice(
                                                                            0,
                                                                            $$i
                                                                          )
                                                                          .concat(
                                                                            $$a.slice(
                                                                              $$i +
                                                                                1
                                                                            )
                                                                          )
                                                                      )
                                                                  }
                                                                } else {
                                                                  _vm.$set(
                                                                    row,
                                                                    "recvYn",
                                                                    $$c
                                                                  )
                                                                }
                                                              },
                                                              function($event) {
                                                                return _vm.setClockStatus(
                                                                  row.recvYn,
                                                                  "danger_time",
                                                                  index
                                                                )
                                                              }
                                                            ]
                                                          }
                                                        }),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "switch_label",
                                                            attrs: {
                                                              for:
                                                                "danger_push_receive_switch" +
                                                                index
                                                            }
                                                          },
                                                          [
                                                            row.recvYn == 1
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "on"
                                                                  },
                                                                  [_vm._v("on")]
                                                                )
                                                              : _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "off"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "off"
                                                                    )
                                                                  ]
                                                                ),
                                                            _c("span", {
                                                              staticClass:
                                                                "onf_btn"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("td", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        padding: "5px",
                                                        display: "flex",
                                                        "justify-content":
                                                          "center",
                                                        "align-items": "center"
                                                      }
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              row.recvStrtTime,
                                                            expression:
                                                              "row.recvStrtTime"
                                                          }
                                                        ],
                                                        class:
                                                          "input_time danger_time" +
                                                          index,
                                                        attrs: {
                                                          type: "time",
                                                          name: "start_time"
                                                        },
                                                        domProps: {
                                                          value:
                                                            row.recvStrtTime
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              row,
                                                              "recvStrtTime",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _vm._v("   ~   "),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              row.recvEndTime,
                                                            expression:
                                                              "row.recvEndTime"
                                                          }
                                                        ],
                                                        class:
                                                          "input_time danger_time" +
                                                          index,
                                                        attrs: {
                                                          type: "time",
                                                          name: "end_time"
                                                        },
                                                        domProps: {
                                                          value: row.recvEndTime
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              row,
                                                              "recvEndTime",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  )
                                                ])
                                              ]
                                            )
                                          }
                                        ),
                                        _vm._m(16),
                                        _vm._m(17)
                                      ],
                                      2
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-right",
                            staticStyle: { "margin-bottom": "15px" }
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-labeled bg-primary",
                                on: { click: _vm.updateData }
                              },
                              [_vm._m(18), _vm._v(" 적용 ")]
                            )
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "pageTitle" } }, [
      _c("b", [_vm._v("푸시 알람 설정")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "treeView" } }, [
      _c("div", { staticClass: "demo-section wide k-content" }, [
        _c("div", { staticClass: "treeview-flex" }, [
          _c("div", { attrs: { id: "treeview-kendo" } })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h6", { staticClass: "panel-title" }, [
      _c("i", { staticClass: "icon-cog3 position-left" }),
      _c("b", [_vm._v("푸시 알림 설정 정보")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-right": "30px" } }, [
      _c("h6", [_vm._v("푸시 알림 수신")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "500px" } }, [_vm._v("대상물")]),
        _c("th", { staticStyle: { width: "120px" } }, [_vm._v("수신여부")]),
        _c("th", [_vm._v("수신시간")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { empty: "" } }, [
      _c("td", { attrs: { colspan: "7" } }, [_vm._v("조회된 항목이 없습니다.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticStyle: { padding: "0px" },
        attrs: { id: "warn-table-tr-for-draw-line" }
      },
      [
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "250px" } }, [_vm._v("대상물")]),
        _c("th", { staticStyle: { width: "250px" } }, [_vm._v("조치대상")]),
        _c("th", { staticStyle: { width: "120px" } }, [_vm._v("수신여부")]),
        _c("th", [_vm._v("수신시간")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { empty: "" } }, [
      _c("td", { attrs: { colspan: "7" } }, [_vm._v("조회된 항목이 없습니다.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticStyle: { padding: "0px" },
        attrs: { id: "danger-table-tr-for-draw-line" }
      },
      [
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticStyle: { "margin-right": "30px" } }, [
      _c("h6", [_vm._v("푸시 알림 수신")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "500px" } }, [_vm._v("대상물")]),
        _c("th", { staticStyle: { width: "120px" } }, [_vm._v("수신여부")]),
        _c("th", [_vm._v("수신시간")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { empty: "" } }, [
      _c("td", { attrs: { colspan: "7" } }, [_vm._v("조회된 항목이 없습니다.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticStyle: { padding: "0px" },
        attrs: { id: "warn-table-tr-for-draw-line" }
      },
      [
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "250px" } }, [_vm._v("대상물")]),
        _c("th", { staticStyle: { width: "250px" } }, [_vm._v("조치대상")]),
        _c("th", { staticStyle: { width: "120px" } }, [_vm._v("수신여부")]),
        _c("th", [_vm._v("수신시간")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", { attrs: { empty: "" } }, [
      _c("td", { attrs: { colspan: "7" } }, [_vm._v("조회된 항목이 없습니다.")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "tr",
      {
        staticStyle: { padding: "0px" },
        attrs: { id: "danger-table-tr-for-draw-line" }
      },
      [
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } }),
        _c("td", { staticStyle: { padding: "0px" } })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [_c("i", { staticClass: "icon-checkmark3" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }