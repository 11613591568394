<template>
	<div id="rootVM" v-if="keyForRerendering != 0" :key="keyForRerendering">
		<div id="pageTitle">
			<b>푸시 알람 설정</b>
		</div>
		<div id="adminView" v-if="loginUserInfo.userRoleCd == '00'">
			<div id="treeView">
				<div class="demo-section wide k-content">
					<div class="treeview-flex">
						<div id="treeview-kendo"></div>
					</div>
				</div>
			</div>
			<div class="content">
				<div>
					<h2 v-if="curCompanyNm">{{ curCompanyNm }} 안전관리자</h2>
					<h2 v-if="curCompanyNm == null">{{ curSiteNm }} 안전관리자</h2>
					<KendoGrid
						ref="supervisorGrid"
						:auto-bind="true"
						:api-url="settingPushAlarmUrl.inqSafetySupervisorInfo"
						:columns="siteSafetySupervisorGridColumns"
						:apply-search-condition="applySearchStateOnGridLoadForGettingSupervisorInfo"
						@selected-row-item-changed="selectedRowItemChanged"
						:height="300"
					></KendoGrid>
				</div>
				<div id="details" class="panel panel-flat" v-if="isEditMode">
					<div class="panel-heading">
						<h6 class="panel-title">
							<i class="icon-cog3 position-left"></i>
							<b>푸시 알림 설정 정보</b>
						</h6>
						<div class="heading-elements">
							<ul class="icons-list">
								<li><a @click="pannelHidden" data-action="collapse"></a></li>
							</ul>
						</div>
					</div>
					<div class="panel-body panel-body-center">
						<div class="row panel-detail">
							<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center">
								<div style="margin-right: 30px"><h6>푸시 알림 수신</h6></div>
								<div style="display: flex; align-items: center">
									<input
										type="checkbox"
										class="switch"
										id="total_push_receive_switch"
										v-model="detailsItem.userInfo.aiPushRecvYn"
										true-value="1"
										false-value="0"
									/>
									<label for="total_push_receive_switch" class="switch_label">
										<div class="on" v-if="detailsItem.userInfo.aiPushRecvYn == 1">on</div>
										<div class="off" v-else>off</div>
										<span class="onf_btn"></span>
									</label>
								</div>
							</div>
							<!-- 주의 알림 설정 -->
							<h4 style="text-align: left">주의 알림 설정</h4>
							<div class="col-md-12" style="height: 35vh; padding-right: 0px; overflow: auto; z-index: 0">
								<div id="danger-table" class="table-wrapper" danger>
									<table class="table table-hover">
										<thead>
											<tr>
												<th style="width: 500px">대상물</th>
												<th style="width: 120px">수신여부</th>
												<th>수신시간</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row, index) in detailsItem.warnWorkPushSetting" :key="index" :id="'warnitem' + index">
												<td>{{ row.objectNm }}</td>
												<td style="text-align: center">
													<div style="display: flex; justify-content: center; align-items: center">
														<input
															type="checkbox"
															class="switch setting_switch"
															:id="'warn_push_receive_switch' + index"
															v-model="row.recvYn"
															true-value="1"
															false-value="0"
															@change="setClockStatus(row.recvYn, 'warn_time', index)"
														/>
														<label :for="'warn_push_receive_switch' + index" class="switch_label">
															<div class="on" v-if="row.recvYn == 1">on</div>
															<div class="off" v-else>off</div>
															<span class="onf_btn"></span>
														</label>
													</div>
												</td>
												<td>
													<div style="width: 100%; padding: 5px; display: flex; justify-content: center; align-items: center">
														<input type="time" :class="'input_time warn_time' + index" name="start_time" v-model="row.recvStrtTime" />
														&nbsp; ~ &nbsp;
														<input type="time" :class="'input_time warn_time' + index" name="end_time" v-model="row.recvEndTime" />
													</div>
												</td>
											</tr>
											<tr empty>
												<td colspan="7">조회된 항목이 없습니다.</td>
											</tr>
											<tr id="warn-table-tr-for-draw-line" style="padding: 0px">
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<!-- 위험 알림 설정 -->
							<h4 style="text-align: left">위험 알림 설정</h4>
							<div class="col-md-12" style="height: 35vh; padding-right: 0px; overflow: auto">
								<div id="danger-table" class="table-wrapper" danger>
									<table class="table table-hover">
										<thead>
											<tr>
												<th style="width: 250px">대상물</th>
												<th style="width: 250px">조치대상</th>
												<th style="width: 120px">수신여부</th>
												<th>수신시간</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row, index) in detailsItem.dangerPushSetting" :key="index" :id="'dangeritem' + index">
												<td>{{ row.objectNm }}</td>
												<td>{{ row.targetNm }}</td>
												<td style="text-align: center">
													<div style="display: flex; justify-content: center; align-items: center">
														<input
															type="checkbox"
															class="switch setting_switch"
															:id="'danger_push_receive_switch' + index"
															v-model="row.recvYn"
															true-value="1"
															false-value="0"
															@change="setClockStatus(row.recvYn, 'danger_time', index)"
														/>
														<label :for="'danger_push_receive_switch' + index" class="switch_label">
															<div class="on" v-if="row.recvYn == 1">on</div>
															<div class="off" v-else>off</div>
															<span class="onf_btn"></span>
														</label>
													</div>
												</td>
												<td>
													<div style="width: 100%; padding: 5px; display: flex; justify-content: center; align-items: center">
														<input type="time" :class="'input_time danger_time' + index" name="start_time" v-model="row.recvStrtTime" />
														&nbsp; ~ &nbsp;
														<input type="time" :class="'input_time danger_time' + index" name="end_time" v-model="row.recvEndTime" />
													</div>
												</td>
											</tr>
											<tr empty>
												<td colspan="7">조회된 항목이 없습니다.</td>
											</tr>
											<tr id="danger-table-tr-for-draw-line" style="padding: 0px">
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="text-right" style="margin-bottom: 15px">
								<button class="btn btn-labeled bg-primary" @click="updateData">
									<b><i class="icon-checkmark3"></i></b>
									적용
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else>
			<div class="content">
				<div id="details" class="panel panel-flat">
					<div class="panel-body panel-body-center">
						<div class="row panel-detail">
							<div style="width: 100%; padding: 10px; display: flex; justify-content: center; align-items: center">
								<div style="margin-right: 30px"><h6>푸시 알림 수신</h6></div>
								<div style="display: flex; align-items: center">
									<input
										type="checkbox"
										class="switch"
										id="total_push_receive_switch"
										v-model="detailsItem.userInfo.aiPushRecvYn"
										true-value="1"
										false-value="0"
									/>
									<label for="total_push_receive_switch" class="switch_label">
										<div class="on" v-if="detailsItem.userInfo.aiPushRecvYn == 1">on</div>
										<div class="off" v-else>off</div>
										<span class="onf_btn"></span>
									</label>
								</div>
							</div>
							<!-- 주의 알림 설정 -->
							<h4 style="text-align: left">주의 알림 설정</h4>
							<div class="col-md-12" style="height: 35vh; padding-right: 0px; overflow: auto; z-index: 0">
								<div id="danger-table" class="table-wrapper" danger>
									<table class="table table-hover">
										<thead>
											<tr>
												<th style="width: 500px">대상물</th>
												<th style="width: 120px">수신여부</th>
												<th>수신시간</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row, index) in detailsItem.warnWorkPushSetting" :key="index" :id="'warnitem' + index">
												<td>{{ row.objectNm }}</td>
												<td style="text-align: center">
													<div style="display: flex; justify-content: center; align-items: center">
														<input
															type="checkbox"
															class="switch setting_switch"
															:id="'warn_push_receive_switch' + index"
															v-model="row.recvYn"
															true-value="1"
															false-value="0"
															@change="setClockStatus(row.recvYn, 'warn_time', index)"
														/>
														<label :for="'warn_push_receive_switch' + index" class="switch_label">
															<div class="on" v-if="row.recvYn == 1">on</div>
															<div class="off" v-else>off</div>
															<span class="onf_btn"></span>
														</label>
													</div>
												</td>
												<td>
													<div style="width: 100%; padding: 5px; display: flex; justify-content: center; align-items: center">
														<input type="time" :class="'input_time warn_time' + index" name="start_time" v-model="row.recvStrtTime" />
														&nbsp; ~ &nbsp;
														<input type="time" :class="'input_time warn_time' + index" name="end_time" v-model="row.recvEndTime" />
													</div>
												</td>
											</tr>
											<tr empty>
												<td colspan="7">조회된 항목이 없습니다.</td>
											</tr>
											<tr id="warn-table-tr-for-draw-line" style="padding: 0px">
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<!-- 위험 알림 설정 -->
							<h4 style="text-align: left">위험 알림 설정</h4>
							<div class="col-md-12" style="height: 35vh; padding-right: 0px; overflow: auto">
								<div id="danger-table" class="table-wrapper" danger>
									<table class="table table-hover">
										<thead>
											<tr>
												<th style="width: 250px">대상물</th>
												<th style="width: 250px">조치대상</th>
												<th style="width: 120px">수신여부</th>
												<th>수신시간</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(row, index) in detailsItem.dangerPushSetting" :key="index" :id="'dangeritem' + index">
												<td>{{ row.objectNm }}</td>
												<td>{{ row.targetNm }}</td>
												<td style="text-align: center">
													<div style="display: flex; justify-content: center; align-items: center">
														<input
															type="checkbox"
															class="switch setting_switch"
															:id="'danger_push_receive_switch' + index"
															v-model="row.recvYn"
															true-value="1"
															false-value="0"
															@change="setClockStatus(row.recvYn, 'danger_time', index)"
														/>
														<label :for="'danger_push_receive_switch' + index" class="switch_label">
															<div class="on" v-if="row.recvYn == 1">on</div>
															<div class="off" v-else>off</div>
															<span class="onf_btn"></span>
														</label>
													</div>
												</td>
												<td>
													<div style="width: 100%; padding: 5px; display: flex; justify-content: center; align-items: center">
														<input type="time" :class="'input_time danger_time' + index" name="start_time" v-model="row.recvStrtTime" />
														&nbsp; ~ &nbsp;
														<input type="time" :class="'input_time danger_time' + index" name="end_time" v-model="row.recvEndTime" />
													</div>
												</td>
											</tr>
											<tr empty>
												<td colspan="7">조회된 항목이 없습니다.</td>
											</tr>
											<tr id="danger-table-tr-for-draw-line" style="padding: 0px">
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
												<td style="padding: 0px"></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div class="text-right" style="margin-bottom: 15px">
								<button class="btn btn-labeled bg-primary" @click="updateData">
									<b><i class="icon-checkmark3"></i></b>
									적용
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import apiIndex from '../../api/index';
import KendoGrid from '@/components/KendoGrid';
import _ from 'lodash';

let axiosExtention;

export default {
	components: {
		KendoGrid: KendoGrid,
	},
	data: () => ({
		loginUserInfo: '',
		siteMap: {},
		siteAndCompanyList: [],
		dataSource: [], //트리에 세팅할 데이터 소스
		// 안전관리자 그리드 컬럼
		siteSafetySupervisorGridColumns: [
			{ field: 'companyNm', title: '업체명', width: '10%' },
			{ field: 'userId', title: '사용자ID', width: '10%' },
			{ field: 'userNm', title: '성명', width: '10%' },
			{ field: 'hpNo', title: '휴대폰번호', dataFormat: 'hyphenPhone', width: '15%' },
		],
		// searchOption: { curCompanyId: '', curSiteId: '' }, //사용자 그리드의 조건 옵션
		searchOptionForGettingSuperVisor: { curSiteIdForGettingSupervisor: '', curCompanyIdForGettingSupervisor: '' }, // 안전관리자 그리드의 조건 옵션
		settingPushAlarmUrl: {
			inqSafetySupervisorInfo: apiIndex.settingPushAlarm.inqSafetySupervisorInfo,
			inqOnePushSetting: apiIndex.settingPushAlarm.inqOnePushSetting,
			updPushSetting: apiIndex.settingPushAlarm.updPushSetting,
		},
		isSite: true,
		isCompany: false,
		//현재 클릭한 현장명
		curSiteNm: '',
		//현재 클릭한 업체명
		curCompanyNm: null,
		userSiteId: '',
		search: {},
		detailsItem: { dangerPushSetting: {}, warnWorkPushSetting: {}, userInfo: {} },
		keyForRerendering: 0,
	}),
	created() {
		this.loginUserInfo = JSON.parse(sessionStorage.getItem('loginUserInfo'));
		axiosExtention = this.$jarvisExtention.axiosExtention;
		if (this.loginUserInfo.userRoleCd == '00') {
			this.userSiteId = this.loginUserInfo.siteId;
			this.searchOptionForGettingSuperVisor.curSiteIdForGettingSupervisor = this.userSiteId;
		} else {
			this.selectedRowItemChanged({ userId: this.loginUserInfo.userId });
		}
		this.keyForRerendering += 1;
	},
	watch: {
		detailsItem() {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (this.isEditMode) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		'detailsItem.userInfo.aiPushRecvYn': {
			handler() {
				this.setAllInputsStatus();
			},
		},
	},
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem.userInfo);
		},
	},
	mounted() {
		if (this.loginUserInfo.userRoleCd == '00') {
			this.getTreeData();
		}
		// this.drawTreeView();
	},
	methods: {
		//TODO: 잘못된 시간을 입력한 부분을 눈에 띄게 만들기
		validateTime() {
			let lengthOfDetailsItem = this.detailsItem.length;
			for (let i = 0; i < lengthOfDetailsItem; i++) {
				if (this.detailsItem[i].recvStrtTime > this.detailsItem[i].recvEndTime) {
					return false;
				}
			}
			return true;
		},
		async updateData() {
			if (this.validateTime() == true) {
				await this.$axios
					.post(this.settingPushAlarmUrl.updPushSetting, this.detailsItem)
					.then(function (r) {
						if (r.status == 200) {
							alert('수정을 완료했습니다.');
						}
					})
					.catch(axiosExtention.buildErrorHandler());
			} else {
				alert('유효한 시간을 입력해주세요');
			}
		},
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('details');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		applySearchStateOnGridLoadForGettingSupervisorInfo(param) {
			return $.extend(true /*deep*/, param, this.searchOptionForGettingSuperVisor || {});
		},
		async getTreeData() {
			const closure = this;
			this.siteAndCompanyList = [];
			await this.$axios
				.post(apiIndex.tree.inqSiteInfoAndCompanyId, this.search)
				.then(async function (r) {
					let lengthOfData = r.data.length;
					for (let i = 0; i < lengthOfData; i++) {
						closure.$set(closure.siteMap, r.data[i].siteNm, r.data[i].siteId);
						if (r.data[i].siteId == closure.userSiteId) {
							closure.curSiteNm = r.data[i].siteNm; //현재 현장명 세팅
						}
						closure.siteAndCompanyList.push({
							siteNm: r.data[i].siteNm,
							siteId: r.data[i].siteId,
							companyNm: r.data[i].companyNm,
							companyId: r.data[i].companyId,
						});
					}
				})
				// .bind(this)
				.catch(axiosExtention.buildErrorHandler());
			this.setDataSource();
		},
		setDataSource() {
			// 혹시 있을 데이터 초기화
			this.dataSource = [];
			let id = 0;
			for (var siteElement in this.siteMap) {
				var site = {};
				site.id = id++;
				site.text = siteElement;
				site.expanded = true;
				site.spriteCssClass = 'site';
				site.key = this.siteMap[siteElement];

				let companys = this.siteAndCompanyList.filter(item => item.siteNm == siteElement && item.companyId != null);
				let lengthOfCompanys = companys.length;
				var siteItems = [];
				for (let i = 0; i < lengthOfCompanys; i++) {
					var siteItem = {};
					siteItem.id = id++;
					siteItem.text = companys[i].companyNm;
					siteItem.expanded = false;
					siteItem.spriteCssClass = 'company';
					siteItem.key = companys[i].companyId;
					siteItem.parentSiteId = this.siteMap[siteElement];
					siteItem.parentSiteNm = siteElement;
					siteItems.push(siteItem);
				}
				site.items = siteItems;
				this.dataSource.push(site);
			}
			this.drawTreeView();
		},
		drawTreeView() {
			$('#treeview-kendo').kendoTreeView({
				dataSource: this.dataSource,
				loadOnDemand: true,
				select: this.treeSelect,
			});
		},
		treeSelect(e) {
			let data = $('#treeview-kendo').data('kendoTreeView').dataItem(e.node);
			this.detailsItem = { dangerPushSetting: {}, warnWorkPushSetting: {}, userInfo: {} };
			this.lengthOfDetailsItem = 0;
			// this.resetSearchOption();
			if (data.spriteCssClass == 'company') {
				// 현재 누른 것은 업체
				this.isSite = false;
				this.isCompany = true;
				// 화면에 관련 정보를 띄워주기 위한 데이터 설정
				this.curCompanyNm = data.text;
				this.curSiteNm = data.parentSiteNm;
				// 안전관리자 조회를 위한 조건 설정
				this.searchOptionForGettingSuperVisor.curCompanyIdForGettingSupervisor = data.key;
				this.searchOptionForGettingSuperVisor.curSiteIdForGettingSupervisor = data.parentSiteId;
				// 그리드 로드
				this.$refs.supervisorGrid.load();
			} else {
				// 현재 누른 것은 현장
				this.isSite = true;
				this.isCompany = false;
				// 화면에 관련 정보를 띄워주기 위한 데이터 설정
				this.curCompanyNm = null;
				this.curSiteNm = data.text;
				// 안전관리자 조회를 위한 조건 설정
				this.searchOptionForGettingSuperVisor.curSiteIdForGettingSupervisor = data.key;
				this.searchOptionForGettingSuperVisor.curCompanyIdForGettingSupervisor = '';
				// 그리드 로드
				this.$refs.supervisorGrid.load();
			}
		},
		// resetSearchOption() {
		// 	this.searchOption = { curCompanyId: '', curSiteId: '' };
		// },
		selectedRowItemChanged(selectedRowItem) {
			this.detailsItem = { dangerPushSetting: {}, warnWorkPushSetting: {}, userInfo: {} };
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				var closure = this;
				this.$axios
					.post(this.settingPushAlarmUrl.inqOnePushSetting, selectedRowItem)
					.then(function (response) {
						if (response.data != '') {
							closure.detailsItem = response.data;
							closure.setTimeFormat();
							closure.$nextTick(function () {
								closure.setAllInputsStatus();
							});
						}
					})
					.catch(axiosExtention.buildErrorHandler());
			}
		},
		setTimeFormat() {
			this.detailsItem.dangerPushSetting.forEach(item => {
				item.recvStrtTime = item.recvStrtTime.slice(0, 2) + ':' + item.recvStrtTime.slice(2);
				item.recvEndTime = item.recvEndTime.slice(0, 2) + ':' + item.recvEndTime.slice(2);
			});
			this.detailsItem.warnWorkPushSetting.forEach(item => {
				item.recvStrtTime = item.recvStrtTime.slice(0, 2) + ':' + item.recvStrtTime.slice(2);
				item.recvEndTime = item.recvEndTime.slice(0, 2) + ':' + item.recvEndTime.slice(2);
			});
		},
		setAllInputsStatus() {
			var switches = document.getElementsByClassName('setting_switch');
			var lengthOfSwitches = switches.length;
			var timeInputs = document.getElementsByClassName('input_time');
			var lengthOfTimeInputs = lengthOfSwitches * 2;

			this.setSwitchesStatus(switches, lengthOfSwitches);
			this.setClockStatusWhenAiPushRecvYnIsChanged(timeInputs, lengthOfTimeInputs);
		},
		setSwitchesStatus(switches, lengthOfSwitches) {
			let aiPushRecvYn = this.detailsItem.userInfo.aiPushRecvYn;
			if (aiPushRecvYn == '1') {
				for (let i = 0; i < lengthOfSwitches; i++) {
					switches[i].disabled = false;
				}
			} else {
				for (let i = 0; i < lengthOfSwitches; i++) {
					switches[i].disabled = true;
				}
			}
		},
		setClockStatusWhenAiPushRecvYnIsChanged(timeInputs, lengthOfTimeInputs) {
			let aiPushRecvYn = this.detailsItem.userInfo.aiPushRecvYn;
			if (aiPushRecvYn == '1') {
				let i = 0;
				Array.from(this.detailsItem.dangerPushSetting).forEach(danger => {
					this.setClockStatus(danger.recvYn, 'danger_time', i++);
				});
				i = 0;
				Array.from(this.detailsItem.warnWorkPushSetting).forEach(warn => {
					this.setClockStatus(warn.recvYn, 'warn_time', i++);
				});
			} else {
				for (let i = 0; i < lengthOfTimeInputs; i++) {
					timeInputs[i].disabled = true;
				}
			}
		},
		setClockStatus(recvYn, flag, index) {
			let timeElements = document.getElementsByClassName(flag + index);
			if (recvYn == '1') {
				for (let i = 0; i < timeElements.length; i++) {
					timeElements[i].disabled = false;
				}
			} else {
				for (let i = 0; i < timeElements.length; i++) {
					timeElements[i].disabled = true;
				}
			}
		},
	},
};
</script>

<style>
/* #rootVM > div > div {
	flex: 1;
	border: 1px solid #ddd;
	display: inline-flex;
	flex-direction: column;
} */
#adminView > div {
	flex: 1;
	border: 1px solid #ddd;
	display: inline-flex;
	flex-direction: column;
}

#rootVM > div > .content {
	border: none !important;
	width: 79%;
	padding: 10px;
}

.panel-body {
	padding: 0px !important;
}

.panel-detail {
	display: inline-block;
	width: 90% !important;
	margin: auto;
	text-align: center;
}

#treeView {
	width: 20%;
	height: 890px;
	margin-right: 1%;
	font-size: 15px;
	font-weight: bold;
	line-height: 15px;
	overflow: auto;
}

.contentRow {
	display: inline-block;
}

@media screen and (max-width: 680px) {
	.treeview-flex {
		flex: auto !important;
		width: 100%;
	}
}

#demo-section-title h3 {
	margin-bottom: 2em;
	text-align: center;
}

.treeview-flex h4 {
	color: #656565;
	margin-bottom: 1em;
	text-align: center;
}

#demo-section-title {
	width: 100%;
	flex: auto;
}

.treeview-flex {
	flex: 1;
	-ms-flex: 1 0 auto;
}

.k-treeview {
	max-width: 240px;
	margin: 0 auto;
}

.demo-section {
	margin-bottom: 5px;
	overflow: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.user {
	background-size: 100%;
	background-image: url('/images/icon-user.png');
}

.site {
	background-size: 100%;
	background-image: url('/images/icon-site.png');
}

.company {
	background-size: 100%;
	background-image: url('/images/icon-build.png');
}

.switch_wrapper {
	position: relative;
	float: right;
	padding-top: 5px;
}
.switch {
	position: absolute;
	/* hidden */
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.switch_label {
	position: relative;
	cursor: pointer;
	display: inline-block;
	width: 60px;
	height: 30px;
	background: #262b40;
	border: 2px solid #262b40;
	border-radius: 20px;
	transition: 0.2s;
}
.switch_label > .off {
	text-align: right;
	padding-top: 3px;
	padding-right: 6px;
	color: white;
}
.onf_btn {
	position: absolute;
	top: 2px;
	left: 6px;
	display: inline-block;
	width: 21px;
	height: 21px;
	border-radius: 20px;
	background: rgb(255, 255, 255);
	transition: 0.2s;
}

.switch:checked + .switch_label {
	background: #46aef0e1;
	border: 2px solid #2eadff;
}
.switch:checked + .switch_label > .on {
	text-align: left;
	padding-top: 3px;
	padding-left: 10px;
}

/* move */
.switch:checked + .switch_label .onf_btn {
	left: 30px;
	background: #f5f5f5;
	box-shadow: 1px 2px 3px #00000020;
}
.table {
	table-layout: fixed;
}
/* table head */
.table-wrapper .table > thead > tr > th {
	background-color: #f5f5f5;

	text-align: center;

	padding: 0.5em 0.6em 0.4em 0.6em;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;

	overflow: auto;
	position: sticky;
	top: 0;
	box-shadow: inset 0 -1px 0 #dedee0;
	z-index: 10;
}

.table-wrapper .table > thead > tr > th:first-child {
	border-width: 0 0 0 0;
}

/* table body */
/* 홀수 */
.table-wrapper .table > tbody > tr:nth-child(2n + 1) {
	background-color: #fff;
}
/* 짝수 */
.table-wrapper .table > tbody > tr:nth-child(2n) {
	background-color: #fbfbfb;
}

.table-wrapper .table > tbody > tr:hover {
	background-color: #b5bdcb;
	background-image: url(/lib/kendo-r2-2019/styles/textures/highlight.png);
	background-position: 50% 50%;
}

.table-wrapper .table > tbody > tr > td:first-child {
	border-width: 0 0 0 0;
}

.table-wrapper .table > tbody > tr > td {
	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;
	padding: 0.4em 0.6em;
	overflow: hidden;
	line-height: 1.6em;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.table-wrapper .table > tbody > tr > td {
	text-align: left;
}

/* table empty */
.table [empty] {
	display: none;
	height: 166px;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}

input[type='checkbox']:disabled ~ .switch_label {
	background: gray;
	border: 2px solid #262b40;
}
input[type='checkbox']:disabled ~ .switch_label > .onf_btn {
	background: lightgray;
}
input[type='time']:disabled {
	background: lightgray;
}
</style>
